#map img {
    display: flex;
    margin: 125px auto auto;
}


/* MOBILE */
@media all and (max-width: 600px) {
    #map img {
        width: 330px;
    }
}