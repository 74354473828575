#meteoptimiste {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D2E1E8;
}


/* MOBILE */
@media all and (max-width: 600px) {
  #meteoptimiste {
    display: block;
  }
}

html, body {
  background-color: #D2E1E8;
}
