#week {

}


/* MOBILE */
@media all and (max-width: 600px) {
    #week {
        width: 500px;
        max-width: 500px;
    }
}