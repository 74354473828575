.header {
    font-size: 34px;
    height: 54px;
    background-color: #94c9ff;
    color: white;
}

.header img {
    width: 54px;
}
.header #informations {

}