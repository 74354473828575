.day {
    height: 50px;
    width: 150px;
    text-align: center;
    line-height: 3;
    display: inline-block;
    cursor: pointer;
    background-color: #bfdae6;
}

.day:hover {
    background-color: #95abb5;
}


/* MOBILE */
@media all and (max-width: 600px) {
    .day {
        font-size: 24px;
        height: 128px;
        width: 47px;
    }
}